import { Col } from "react-bootstrap";

export const ProjectCard = ({ url, imgUrl }) => {
  return (
    <Col size={12} sm={6} md={4}>
      <a href={url}>
      <div className="proj-imgbx">
        <img src={imgUrl} />
        
      </div>
      </a>
    </Col>
  )
}
